@import "./variables";

.body {
  font-family: "Inter", sans-serif;
  color: $dark-gray !important;
}

// Theme color
.purple-saas22 {
  color: $themeBlue;
}
.themegreen {
  color: $themeGreen;
}

.bg-themeyellow {
  background: $themeyellow;
}

.bg-purple-saas22 {
  background: $themeBlue;
}

.midnight-blue {
  color: $midnight-blue;
}

.common-route-background {
  background: $common-route-background;
}

.Grayish {
  color: #7b7b7b;
}

.dark-gray {
  color: $dark-gray;
}

.royal-blue {
  color: $royal-blue;
}

.steel-gray {
  color: $steel-gray;
}

.midnight-blue {
  color: $midnight-blue;
}

.light-gray {
  color: $light-gray;
}

.goldenrod {
  color: $goldenrod;
}

.silver {
  color: $silver;
}

.white {
  color: $white;
}

.black {
  color: $black;
}

.translucent-black {
  color: $translucent-black;
}

.medium-blue {
  color: $medium-blue;
}

.mint {
  color: $mint;
}

.forest-green {
  color: $forest-green;
}

.transparent-black {
  color: $transparent-black;
}

.charcoal-gray {
  color: $charcoal-gray;
}

.slate-blue {
  color: $slate-blue;
}

.sunshine-yellow {
  color: $sunshine-yellow;
}

.powder-blue {
  color: $powder-blue;
}
.tertiary {
  color: $Tertiary;
}
.items-self-start {
  align-items: self-start;
}

// Input styles
.ant-input {
  min-height: 37px;
}

.ant-input:placeholder-shown {
  color: $steel-gray;
}

.ant-input-affix-wrapper {
  border-color: $light-gray;
}

.ant-input-affix-wrapper-sm {
  padding: 0px 10px;
}

.ant-input-affix-wrapper-status-error.ant-input-affix-wrapper {
  border-color: #dc2626 !important;
  box-shadow: 0px 1px 2px 0px #0000000d;
}

.ant-input-disabled,
.ant-input[disabled] {
  color: $dark-gray;
}

// checkbox
.ant-checkbox {
  border: 1px solid $dark-gray;
}

.ant-checkbox-disabled {
  border: 1px solid #fff;
}

.search-prefix {
  color: $steel-gray;
  margin-inline-end: 8px;
  height: 20px;
}

.search-suffixClear {
  color: $midnight-blue;
  width: 20px;
}

// Tabs styles
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $dark-gray;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid $white;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

.ant-tabs-tab.ant-tabs-tab-active {
  font-weight: 600;
}

.table-cursor .ant-table-row {
  cursor: pointer;
}

// Modal popup styles
.signin {
  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding: 10px 15px;
    border-bottom: 1px solid #eee;
  }

  .ant-modal-footer {
    padding: 15px;
  }
}

.ant-modal .ant-modal-content {
  // padding: 0;
  border-radius: 4px;
}

.ant-modal-header {
  padding: 10px 15px;
  border-bottom: 1px solid $silver;
  margin-bottom: 0px;
}

.ant-modal-body {
  padding: 10px 15px;
}

.ant-modal-footer {
  padding: 10px 15px;
  margin-top: 0px;
}

.ant-modal .ant-modal-close {
  top: 15px;
  color: #11182d;
  width: 10px;
  height: 10px;
}

.fs-35 {
  font-size: 35px;
}

// Drawer styles
.ant-drawer-right > .ant-drawer-content-wrapper {
  box-shadow: -8px 16px 25px 13px rgba(0, 0, 0, 0.1);
}

.ant-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.header-false-margin .ant-drawer-body {
  margin-top: -20px;
}

// Tags styles
.ant-tag {
  border-radius: 12px;
  border: none;
  padding: 2px 12px;
  font-weight: 500;
  margin-right: 0px;
}

.ant-tag-blue {
  color: $medium-blue;
  background: $powder-blue;
}

.ant-tag-green {
  background: $mint !important;
  color: $forest-green !important;
  size: 14px;
  border-radius: 4px;
}

.blue-tag {
  color: #1e40af;
  background: #dbeafe;
}

.close-tag {
  color: #333333;
  background-color: rgba(146, 137, 137, 0.2);
}

.delete-tag,
.admin-tag {
  color: #991b1b;
  background: #fee2e2;
}

.pending-tag {
  color: #92400e;
  background: #fef3c7;
}

.revoked-tag {
  color: #1f2937;
  background: #f3f4f6;
}

// .revoked-tag {
//     color: #991B1B;
//     background: #FEE2E2;
// }

.open-tag {
  color: #1e40af;
  background: #dbeafe;
}

.rejected-tag {
  color: #991b1b;
  background: #fee2e2;
}

.expert-tag {
  color: #5b21b6;
  background: #ede9fe;
}

.approved-tag {
  color: #3c7a46;
  background: rgba(85, 175, 104, 0.2);
}

.service-offer {
  color: #111827;
  background: #f6f6f6;
}

.webFeed-tag {
  color: #3c7a46;
  background: rgba(80, 171, 100, 0.2);
}

.project-tag {
  color: #454bb5;
  background: rgba(93, 95, 243, 0.2);
}

.guide-tag {
  color: #be363c;
  background: rgba(234, 90, 84, 0.2);
}

.story-tag {
  color: #c39443;
  background: rgba(247, 207, 102, 0.2);
}

// Card styles
.partners-card:hover,
.dashboard-card:hover {
  box-shadow: 5px 7px 1px 4px $goldenrod;
  transition-delay: 0.3s;
  transition: 0.3s;
}

// Stories card styles
.storiesCard {
  border: 1px solid #e5e7eb;
  overflow: hidden;

  &.bordercolor-black {
    border: 1px solid var(--Text, rgba(51, 51, 51, 1));
  }
}

.eventCard,
.dealsCard {
  min-height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.h-6 {
  height: 1.4rem;
}

.dealsCard {
  min-height: 350px;
  border-radius: 12px;

  &.dealcard0:hover {
    box-shadow: 10px 10px 0px 0px #ea5a54;
  }

  &.dealcard1:hover {
    box-shadow: 10px 10px 0px 0px #f7cf66;
  }

  &.dealcard2:hover {
    box-shadow: 10px 10px 0px 0px #5d5ff3;
  }

  &.dealcard3:hover {
    box-shadow: 10px 10px 0px 0px #55af68;
  }
}

.upcomingEvents {
  box-shadow: 10px 10px 0px 0px #ffffff;
}

.upcomingEvents:hover {
  box-shadow: 10px 10px 0px 0px #55af68;
}

// Table styles
.ant-table {
  border-radius: 6px;
  box-shadow: 0px 1px 3px 0px $transparent-black;
  border: 1px solid $light-gray;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  border: none;
  height: 40px;
  border-bottom: 1px solid $light-gray;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  text-transform: uppercase;
  font-weight: 500;
  color: $dark-gray;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  white-space: nowrap;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  min-width: 100px;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

// Form styles
.form-center {
  width: 60%;
  margin: 0px auto;
}

// Select styles
.ant-select-single {
  min-height: 37px;
}

// Date picker styles
.ant-picker {
  min-height: 37px;
}

// Button styles
.ant-btn {
  height: 48px;
  transition: 0.4s;
}

.ant-btn-sm-custom {
  height: 36px;
  min-width: 80px !important;
}

.btn-default {
  height: 38px;
}

.ant-btn-default:disabled,
.ant-btn-default.ant-btn-disabled .btn-default {
  border: 1px solid $LightGrayish;
  color: $LightSilver;
  background-color: $white;
  box-shadow: 0px 1px 2px 0px $TransparentBlack;
}

.purple-btn {
  box-shadow: 0px 1px 2px 0px $TransparentBlack;
  background: $royal-blue;
  color: $white;
  height: 36px;

  // &:hover {
  //     color: $white !important;
  // }
}

.ant-btn-primary {
  min-width: 180px;
  border: 1px solid $dark-gray;
  color: $dark-gray;
  background: $white;
  padding: 2px 26px;
  border-radius: 60px;
  font-weight: 700;
  box-shadow: 4px 5px 0px 0px $sunshine-yellow;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-secondary {
  min-width: 180px;
  border: 1px solid $dark-gray;
  color: $dark-gray;
  background: $white;
  padding: 2px 26px;
  border-radius: 60px;
  font-weight: 700;
  box-shadow: 4px 5px 0px 0px $charcoal-gray;
}

.ant-btn-default {
  min-width: 180px;
  border: 1px solid $dark-gray;
  color: $dark-gray;
  background: $white;
  padding: 2px 26px;
  border-radius: 60px;
  font-weight: 700;
  box-shadow: 4px 5px 0px 0px $charcoal-gray;
}

.ant-btn-primary:disabled {
  box-shadow: 4px 5px 0px 0px $sunshine-yellow;
  color: rgb(0 0 0 / 36%);
  background-color: rgb(0 0 0 / 0%);
}

.ant-btn-secondary:disabled {
  box-shadow: 4px 5px 0px 0px $charcoal-gray;
  color: rgb(0 0 0 / 36%);
  background-color: rgb(0 0 0 / 0%);
}

.ant-btn-primary.ant-btn-dangerous {
  background-color: #ffffff;
  color: #000;
  min-width: auto;
  box-shadow: 4px 5px 0px 0px $themeRed;
}

.ant-btn:hover {
  color: #000 !important;
  background: #fff !important;
  box-shadow: none;
  transition: 0.4s;
}

.ant-btn:disabled:hover {
  color: #d1d5db !important;
}

// Required field indicator
.ant-form-item .ant-form-item-label > label.ant-form-item-required::before {
  color: $slate-blue !important;
  position: absolute;
  right: 0;
  top: 0;
}

// Tooltip styles
.ant-tooltip-placement-top .ant-tooltip-inner {
  padding: 5px 16px !important;
  font-weight: 500;
  width: 300px;
  border-radius: 8px;
}

// Form styles
.ant-form-vertical .ant-form-item-label > label {
  color: $slate-blue;
  font-weight: 500;
}

// Divider styles
.ant-divider .ant-divider-inner-text {
  font-size: 14px;
  color: #7b7b7b;
  font-weight: 400;
}

// Sign page styles
.sign-page {
  height: calc(100vh - 50px);
  align-items: center;
  display: grid;
  padding: 15px;
  padding-top: 40px;

  .ant-form {
    min-width: 350px;
  }
}

// Google button styles
.google-btn .g-icon {
  position: absolute;
  left: 15px;
}

// Form item error message styles
.ant-form-item .ant-form-item-explain-error {
  text-align: start;
  margin-top: 5px;
}

// Steps styles
.ant-steps.ant-steps-small {
  .ant-steps-item-icon {
    height: 10px;
    width: 50px;
    margin-inline: 0 0px;
  }

  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: rgba(0, 0, 0, 0.06);
      border-color: transparent;
    }
  }

  .ant-steps-item-process {
    .ant-steps-item-icon {
      background-color: $goldenrod;
      border-color: $goldenrod;
    }
  }

  &.ant-steps-horizontal:not(.ant-steps-label-vertical) {
    .ant-steps-item {
      padding-inline-start: 0px;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: $goldenrod;
      border-color: $goldenrod;
    }
  }

  text-align: center;
  display: block;

  .ant-steps-icon {
    display: none;
  }
}

// Testimonial card styles
.testimonial-card {
  text-align: justify;
}

// Markdown styles
.markdown {
  // text-align: justify;

  p {
    margin-bottom: 10px;
  }

  h1 {
    margin-bottom: 5px;
    font-size: 20px;
  }
}

// Content threeline styles
.content-threeline {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.content-fiveline {
  -webkit-line-clamp: 5;
  line-clamp: 5;
}

.content-twoline {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.content-oneline {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

// Hover effect for links
a:hover {
  color: $black;
}

.markdown > * {
  all: revert;
  margin: 0;
}

.markdown ul ul,
.markdown ul ol,
.markdown ol ul,
.markdown ol ol {
  list-style: circle;
  padding-left: 20px;
}

.markdown ul ul ul,
.markdown ul ul ol,
.markdown ol ul ul,
.markdown ol ul ol {
  list-style: square;
  padding-left: 20px;
}

.markdown blockquote {
  padding-left: 20px;
}

.sidebar-open {
  transform: translateX(0);
}

.sidebar-closed {
  transform: translateX(-100%);
}

.transition-all {
  transition: all 0.3s ease;
}

// Removing input background colour - autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

// loader css
.loader {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}

.innerHTml {
  p {
    text-align: left;
  }
}

figure.image {
  margin: 0px auto;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
  }
}

.image.image_resized {
  margin: 0px auto;
}

// upload css
.ant-upload-wrapper .ant-upload-drag {
  border: none;
  background: none;
  text-align: start;
}

.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
  padding: 0px;
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item.ant-upload-list-item-undefined {
  display: none;
}

.upload-default-image {
  height: 100px;
  width: 100px;
}

// .story-detail-section {
//   p {
//     text-align: justify;
//   }
// }

.story-detail-section.container-class {
  table {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  table tr td {
    padding: 10px;
    cursor: pointer;
    vertical-align: top;
  }

  td img {
    border-radius: 8px;
  }

  td:nth-child(1) img:hover {
    box-shadow: 10px 10px 0px 0px $themeyellow;
  }

  td:nth-child(2) img:hover {
    box-shadow: 10px 10px 0px 0px $themeBlue;
  }

  td:nth-child(3) img:hover {
    box-shadow: 10px 10px 0px 0px $themeRed;
  }

  img:nth-child(1):hover {
    box-shadow: 10px 10px 0px 0px $themeyellow;
  }

  img:nth-child(2):hover {
    box-shadow: 10px 10px 0px 0px $themeBlue;
  }

  img:nth-child(3):hover {
    box-shadow: 10px 10px 0px 0px $themeRed;
  }
}

.userEmail {
  .tag {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* Adjust based on design requirements */
    margin: 5px;
    /* Padding for aesthetics */
    border-radius: 50px;
    /* Optional styling */
  }

  .tagSuccess {
    border: 1px solid #7b7b7b;
    background-color: #fff;
    /* Example background */
  }

  .tagFail {
    border: 1px solid var(--Red-SaaS22, #ea5a54);
    background-color: #ea5a541a;
    /* Example background */
  }

  .tag img {
    margin-right: 10px;
    /* Space between the avatar icon and the label */
  }

  .tag .close-icon {
    margin-left: auto;
    /* Pushes the close icon to the far right */
  }
}

.max-w-150px {
  max-width: 150px;
}

.opacity-4 {
  opacity: 0.4;
}

.not-found-page {
  height: calc(100vh - 100px);
  max-width: 400px;
  margin: 0px auto;
}

.accessDenied {
  height: calc(100vh - 100px);
  max-width: 600px;
}

.bd-radius {
  .ant-upload {
    border-radius: 50% !important;
  }

  .ant-upload-select {
    overflow: hidden;
    border-radius: 50%;
    margin-inline-end: 0 !important;
  }

  .img-url {
    border-radius: 50%;
    height: 100px;
    width: 100px;
  }
}

.footer-text-center {
  .ant-modal-footer {
    text-align: center;
  }
}

.jobs-img {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 1px solid #ededed;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding: 20px;

  img {
    border-radius: 50%;
    max-height: 100%;
    width: auto;
    height: auto;
    display: block;
  }
}

// .range-picker {
.ant-picker .ant-picker-input > input {
  width: 100px;
}

.upload-button-custom {
  height: auto;
  border: none;
  box-shadow: none;

  &:disabled {
    height: auto;
    border: none;
    box-shadow: none;
  }
}

#fc_frame,
#fc_frame.fc-widget-normal {
  z-index: 6 !important;
}

.drawer-filter {
  .ant-select-single {
    width: 100% !important;
  }

  &.ant-drawer-content {
    border-radius: 20px 20px 0px 0px !important;
  }

  .ant-drawer-header {
    border-bottom: none;
    padding: 24px;
  }

  .ant-drawer-title {
    font-size: 18px;
  }
}

.dot {
  position: relative;
}

.dot::before {
  content: "";
  position: absolute;
  top: 6px;
  right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #eb5757;
}

.ant-drawer-bottom > .ant-drawer-content-wrapper {
  box-shadow: none;
}

.onboard-shadow {
  box-shadow: 0px -3px 8px 0px #0000001a;
}

.ant-carousel .slick-dots li {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #bcc1c8;
  border: 1px solid #bcc1c8;
}

.ant-carousel .slick-dots li.slick-active {
  width: 6px;
  border: 1px solid #bcc1c8;
}

.ant-carousel .slick-dots li button {
  height: 6px;
}

.ant-carousel .slick-dots {
  z-index: 4;
}

// tooltip
.recharts-default-tooltip {
  padding: 4px 10px !important;
  border-radius: 8px;
  font-size: 14px;
}

.custom-tooltip-pie {
  background: #010101;
  color: #ebecec;
  border-radius: 8px;
  font-size: 14px;
}

.story-detail-section.last-index-class {
  padding-bottom: 48px;
}

// #section-0.story-detail-section.last-index-class {
//   margin-bottom: 0px;
// }

.add-remove-profile {
  .ant-upload-wrapper {
    width: auto;
  }
}

.apply-join-centeralign {
  height: calc(100vh - 40px);
}

// accordition
.ant-collapse {
  background: #fff;
  border: none;
}

.ant-collapse > .ant-collapse-item {
  border: 1px solid #d0d4da;
  border-radius: 8px;
  margin-bottom: 25px;
}

.ant-collapse .ant-collapse-content {
  color: #333333;
  background: none;
  border-top: none;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 8px 8px 8px 8px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  position: absolute;
  right: 0;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 14px 30px 14px 14px;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #ffffff;
}

.ant-collapse > .ant-collapse-item.ant-collapse-item-active > .ant-collapse-header {
  border-bottom: 1px solid #d0d4da;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 18px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {
  height: 24px;
}

.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 8px 8px 0px 0px;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(90deg) !important;
}

.ant-collapse > .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(270deg) !important;
}

// notes
.notes-border-class-parent {
  border-top: 1px solid #d0d4da;
  padding-top: 10px;
}

.edit-delteicon {
  box-shadow: 0px -2px 5px 0px #0000001a;
  height: 40px;
  width: 48px;
  color: #7b7b7b;
}

.parent-icon {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.note-history:hover .parent-icon {
  opacity: 1;
}

.notes-border-class {
  position: relative;
  z-index: 2;
  background: #fff;
  overflow: hidden;
  height: 40px;
  overflow: auto;
}

.notes-field {
  border: 1px solid #d0d4da;
  border-radius: 8px;

  .ant-input-outlined {
    border-width: 0px;
  }

  .bottom-notes {
    padding: 5px 11px;
    border-top: 1px solid #d0d4da;
    height: 60px;
  }

  .ant-btn {
    height: 36px;
    min-width: 80px;
  }
}

.custom-scrollerbar2 {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    margin: 3px;
    padding: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 3px;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5) !important;
    border-radius: 2px;
  }
}

.ul-index-max-h {
  max-height: calc(100vh - 170px);
  overflow: auto;
}

// Responsive styles
@media (max-width: 1440px) {
  .mx-auto {
    margin-left: 0px;
    margin-right: 0px;
  }

  .container {
    max-width: 100%;
  }

  .block-small-webscreen {
    display: grid !important;
  }

  .projectCard {
    min-height: 280px;
  }

  .projectCardadmin {
    min-height: 300px;
  }
}

@media (min-width: 1280px) {
  .mw-detailcontent {
    flex: 0 0 65%;
    max-width: 65%;
  }

  .detailpage-illustration {
    display: block;
  }
}

@media (max-width: 1280px) {
  .gm-card-homepage-res-h {
    height: 150px;
  }

  .gm-card-homepage-res-size {
    font-size: 25px;
    line-height: 25px;
  }

  .index-parent {
    width: 180px;
  }
}

// mobile responsive

.filter-icon {
  border: 1px solid #e5e7eb;
  height: 37px;
  width: 37px;
  border-radius: 6px;
  color: #7b7b7b;
}

.ant-steps.ant-steps-small {
  margin-top: 50px;
}

.overflow-class {
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
}

.position-story {
  position: absolute;
  top: 15px;
  right: 20px;
}

@media (max-width: 975px) {
  .res-right-line {
    border: none;
    display: flex;
    align-items: center;
    gap: 10px;

    div {
      min-width: 30%;
      text-align: start;
    }
  }
}

.res-rowGap {
  row-gap: 1px !important;
}

@media (max-width: 768px) {
  .ant-tabs > .ant-tabs-nav {
    gap: 10px;
  }

  .parent-tab .ant-tabs-nav {
    position: relative;
    z-index: 1;
    top: 0px;
  }

  .service-box-projects {
    height: 8rem;
    display: flex;
    align-items: center;
    border-radius: 8px;
  }

  .res-font-16 {
    font-size: 16px;
  }

  .res-font-18 {
    font-size: 18px;
  }

  .service-box-projects .pad-services {
    padding: 15px;
  }

  .ant-btn-primary,
  .ant-btn-secondary {
    min-width: 120px;
  }

  .ant-drawer-right > .ant-drawer-content-wrapper {
    height: 100vh;
    padding: 10px;
    width: 100% !important;
  }

  .res-align-button {
    position: fixed;
    bottom: 0px;
    background: #fff;
    padding: 12px 20px;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0px auto;
    gap: 10px;
    box-shadow: 0px -3px 8px 0px #0000001a;

    Button {
      width: 100%;
    }
  }

  #fc_frame,
  #fc_frame.fc-widget-normal {
    bottom: 80px !important;
    right: 10px !important;
  }

  .tab-secondary .ant-tabs-tabpane {
    padding: 15px;
  }

  .tab-secondary .ant-tabs-nav {
    padding-left: 10px;
  }

  .jobs-img {
    height: 45px;
    width: 45px;
  }

  .dealsCard {
    width: 300px;
  }

  .whitespace-promo {
    max-width: 110px;
  }

  .deals-container {
    overflow-x: scroll;
  }

  .deals-scroll {
    display: flex;
  }

  .deal-child {
    min-width: 350px;
    flex: 0 0 auto;
  }

  .vertical-line-h {
    display: none;
  }

  .res-row-class {
    flex-direction: column-reverse;
  }

  .header-gap {
    gap: 16px 16px !important;
  }

  .deal-subpage {
    .dealsCard {
      width: 100%;
    }

    .deal-card-col {
      min-width: 100% !important;
    }
  }

  figure.table {
    margin: 0px auto;
  }

  .ant-btn {
    height: 42px;
    transition: 0.4s;
  }

  .ant-tag {
    margin-right: 0px;
  }

  .story-detail-section.container-class {
    td img {
      border-radius: 2px;
    }

    td:nth-child(1) img:hover {
      box-shadow: 5px 5px 0px 0px $themeyellow;
    }

    td:nth-child(2) img:hover {
      box-shadow: 5px 5px 0px 0px $themeBlue;
    }

    td:nth-child(3) img:hover {
      box-shadow: 5px 5px 0px 0px $themeRed;
    }

    img:nth-child(1):hover {
      box-shadow: 5px 5px 0px 0px $themeyellow;
    }

    img:nth-child(2):hover {
      box-shadow: 5px 5px 0px 0px $themeBlue;
    }

    img:nth-child(3):hover {
      box-shadow: 5px 5px 0px 0px $themeRed;
    }
  }

  .ant-drawer .ant-drawer-content {
    border-radius: 4px;
  }

  .mobile-index-modal.ant-modal .ant-modal-content {
    padding: 10px 20px;
  }

  .ant-drawer-top > .ant-drawer-content-wrapper {
    margin: 0px 10px;
    box-shadow: 0px 1px 2px 0px #0000000f;
    box-shadow: 0px 1px 3px 0px #0000001a;
    top: 70px;
    min-height: auto !important;
    height: auto !important;
    max-height: 70vh !important;
    background: #fff;
  }

  .ant-drawer.ant-drawer-top {
    z-index: 4;
  }

  .ant-drawer-top .ant-drawer-mask {
    background: none;
  }

  .logo-image {
    width: 18px;
    height: 18px;
  }

  .tool-item {
    padding: 7px 7px;
  }

  .ant-steps.ant-steps-small {
    display: flex;
    margin-top: 50px;
    margin-bottom: 20px;
    flex-direction: row;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item {
    display: flex;
    flex-direction: row;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item:first-child {
    justify-content: end;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item:nth-child(2) {
    justify-content: start;
  }

  .ant-picker-panels .ant-picker-panel:nth-child(2) {
    display: none;
  }

  .ant-picker-header-next-btn,
  .ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .partner-page {
    .ant-form-vertical .ant-form-item-label > label {
      align-items: self-start;
    }

    .ant-form-item .ant-form-item-label > label.ant-form-item-required::before {
      position: relative;
      top: 4px;
    }
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    margin-inline-end: 0px;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    display: none;
  }

  .ant-steps.ant-steps-small {
    gap: 8px;
  }

  .overflow-class {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

@media (max-width: 600px) {
  .story-page .tool-item {
    width: 48%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

@media (min-width: 768px) {
  // Required field indicator
  .ant-form-item .ant-form-item-label > label.ant-form-item-required::before {
    color: $slate-blue !important;
    position: absolute;
    right: 0;
    top: 0;
  }

  // scrollerbar
  ::-webkit-scrollbar {
    width: 8px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 1px;
  }

  ::-webkit-scrollbar-thumb {
    border: 8px solid;
    border-image: linear-gradient(
      to bottom,
      $themeBlue 0%,
      $themeBlue 33%,
      $themeyellow 33%,
      $themeyellow 66%,
      $themeRed 66%,
      $themeRed 100%
    );
    border-image-slice: 2;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }

  .ant-select-dropdown {
    width: 200px !important;
  }

  .full-width-select .ant-select-dropdown {
    width: 100% !important;
  }
}
