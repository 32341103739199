@import url('../../_variables.scss');

.sign-card {
    border: 1px solid #010101;
    padding: 60px 30px;
    border-radius: 8px;
    gap: 10px;
    display: grid;
    width: 500px;
    max-width: 500px;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #7B7B7B;
    transition: 0.4s;

    &:hover {
        box-shadow: 14px 15px 1px #F7CF66;
        transition: 0.4s;
    }
}

.onboard-formbody {
    min-height: calc(100vh - 142px);
    padding-bottom: 142px;
}

.checkbox-container {
    min-height: 44px;
    box-shadow: 0px 1px 2px 0px #0000000D;
    padding: 8px 16px;
    border: 1px solid #010101;
    border-radius: 6px;
}

.logo-image {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.toolName {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.linkexpired {
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
}

.email-section-h {
    height: calc(100vh - 65px);
}


.googlePictures {
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.auth-logo {
    // position: absolute;
    // top: 15px;
    // left: 30px;
    height: 28px;
}

.sign-page {
    .ant-form-item .ant-form-item-label>label.ant-form-item-required::before {
        content: "";
    }
}

.error-div {
    background: #FEF2F2;
    padding: 20px;
    border-radius: 6px;
    max-width: 500px;
}

.forgot-password-div {
    right: 0;
    top: 0px;
    font-weight: 500;
}

@media (max-width: 768px) {
    .sign-card {
        width: 100%;
        border: none;
        padding: 10px ;

        &:hover {
            box-shadow: none;
        }
    }
}