.service-box-projects {
    border-radius: 20px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);


    .pad-services {
        padding: 30px 0px 30px 30px;

    }
}

.uploaded-file-style {
    padding: 4px 8px;
    background: #F6F6F6;
    border-radius: 6px;
}

.request-img-h {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertical-line-h {
    min-height: calc(100vh - 110px);
}

.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    background: #F6F6F6;
    padding: 14px 8px;
    border-radius: 6px;
}

.index-mobileview.project-view {
    right: 0px;
    bottom: 0px;
    position: relative;
}

.height-fix-request {
    height: calc(100vh - 96px);
    overflow: hidden;
    overflow: auto;
}

.notes-collapse {
    .ant-collapse .ant-collapse-content-hidden {
        display: block;
    }
}

.note-history {
    border-bottom: 1px solid #F2F2F2;
}

.conversation-box {
    // height: calc(100vh - 300px);
    overflow: hidden;
    overflow: auto;
    padding-bottom: 50px;
}

.box-reply {
    border: 1px solid #333333;
    // padding: 16px;
    border-radius: 8px;
    width: 100%;

    .ant-input {
        border: none;
    }
}

.reply .notes-field {
    border-radius: 0;
}

.test1 {
    padding-left: 8px;
    padding-right: 8px;
    height: calc(100vh - 100px);
    overflow: hidden;
    overflow: auto;
}

.test2 {
    padding-left: 8px;
    padding-right: 8px;
    height: calc(100vh - 100px);
    overflow: hidden;
    overflow: auto;
}