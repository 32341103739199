.home-gif-img {
  height: 280px;
}
.home-container {
  max-width: 800px;
  height: auto;
}

.search-results-container {
  box-shadow: 0px 0px 0px 1px #0000000d;

  img {
    width: 20px;
    height: 20px;
  }
}

.banner-effect {
  box-shadow: 0px 3px 5px 0px #0000001a;
  border: 1px solid var(--Border, #d0d4da);
  border-radius: 16px;
  height: 230px;
  overflow: hidden;
  display: flex !important;

  .banner-effect-img {
    height: 230px;
    width: 100%;
    overflow: hidden;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .feature-sec {
    background: #fff;
    width: 50px;
    overflow: hidden;
    position: relative;
    margin: 0px auto;
    display: flex;
    align-items: center;
  }

  .feature-text {
    position: absolute;
    transform: rotate(90deg);
    left: -17px;
  }
}

.projects-stats {
  border: 1px solid var(--Border, #d0d4da);
  padding: 15px 15px;
  border-radius: 8px;
}

.homeChart {
  margin-top: -100px;
}

.homeTab {
  .ant-tabs-content {
    background: #f8f8f8;
    padding: 20px 30px;
    border-radius: 8px;
    min-height: 300px;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 1px solid #f8f8f8 !important;
  }

  .home-sub-tab {
    .ant-tabs-content {
      padding: 0px;
    }

    .ant-tabs-ink-bar {
      background: none;
    }
  }
}

.home-sub-tab-all {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #c39443 !important;
  }
}

.home-sub-tab-projects {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #5d5ff3 !important;
  }
}

.home-sub-tab-web-feed {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #55af68 !important;
  }
}

.home-sub-tab-stories {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #c39443 !important;
  }
}

.home-sub-tab-guides {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ea5a54 !important;
  }
}

.dashboardTab-card {
  box-shadow: 0px 1px 3px 0px #0000001a;
  border-radius: 8px;
  padding: 24px;
  background: #ffffff;

  .ant-tag {
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .banner-effect {
    height: 124px;
  }

  .banner-effect .feature-sec {
    width: 30px;
  }

  // .banner-effect .banner-effect-img {
  //     height: 124px;
  //     background-size: contain; // Maintain full visibility on smaller screens
  // }

  .banner-effect .feature-text {
    left: -12px;
  }

  .homeTab {
    .ant-tabs-content {
      padding: 10px 15px;
    }
  }

  .dashboardTab-card {
    padding: 16px;
  }

  .content-oneline.res-online {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .home-gif-img {
    height: 200px;
  }
}

@media (max-width: 1200px) {
  .parent-home-gif-img {
    display: none;
  }
}

@media (max-width: 975px) {
  .banner-effect .banner-effect-img {
    background-size: cover;
    height: 185px;
  }

  .banner-effect {
    height: 185px;
  }
}

@media (max-width: 600px) {
  .banner-effect .banner-effect-img {
    height: 160px;
  }

  .banner-effect {
    height: 160px;
  }
}

@media (max-width: 500px) {
  .banner-effect .banner-effect-img {
    height: 130px;
  }

  .banner-effect {
    height: 130px;
  }
}

@media (max-width: 400px) {
  .banner-effect .banner-effect-img {
    height: 100px;
  }

  .banner-effect {
    height: 100px;
  }
}
