@import url("../../_variables.scss");

.parent-tab {
  .ant-tabs-nav-wrap {
    justify-content: center;
    min-width: 300px;
  }

  .ant-tabs-nav {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 18px;
    margin: 0 auto;
    width: fit-content;
  }

  .child-tab {
    .ant-tabs-nav-wrap {
      justify-content: start;
    }

    .ant-tabs-nav {
      position: relative;
      z-index: auto;
      left: auto;
      right: auto;
      top: auto;
      width: 100%;
      margin-bottom: 5px;
    }

    .ant-tabs-extra-content {
      margin-bottom: -2px;
    }
  }
}

.detailpage-illustration {
  position: fixed;
  right: 0;
  z-index: -1;
  top: 30%;
  height: 400px;
}

.tab-secondary {
  .ant-tabs-nav {
    margin-bottom: 0px;
    padding-left: 30px;
  }

  .ant-tabs-tab {
    background: #fff;
    padding: 6px 16px;
    color: #010101;
    font-weight: 700;
    border-bottom: none;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid rgba(1, 1, 1, 1);
    border-bottom: none;
    background: rgba(247, 207, 102, 0.2);
  }

  .ant-tabs-tabpane {
    padding: 30px;
    border-radius: 0px 0px 4px 4px;
    border-radius: 16px;
    border: 1px solid #010101;
    overflow: hidden;
  }

  .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 12px;
  }

  .ant-tabs-tab-active {
    background: #fff;
    border-bottom: none;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    background: #fff;
    display: none;
  }
}

.tool-item {
  border: 1px solid #333333;
  padding: 8px 12px;
  border-radius: 6px;

  .tool-image {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}

.testimonial-section {
  background: #5e60f2;
  color: #fff;
  padding: 30px;
  border-radius: 20px;
}

.split-columns {
  column-count: 2;
  column-gap: 16px;
}

.dynamic-image {
  width: 100%;
  overflow: hidden;
  max-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dynamic-image-max {
  max-height: 500px;
}

.testimon {
  height: 50px;
  position: absolute;
}

.testimonial-image1 {
  transform: rotate(180deg);
  top: 0px;
  left: 0px;
}

.testimonial-image2 {
  right: 0px;
  bottom: 0px;
}

.testimonial-card {
  padding: 30px 50px;
  margin: 20px 40px;
  border-radius: 30px;
  font-size: 20px;
}

.rounded-full {
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.detailpage-rounded {
    height: 38px;
    width: 38px;
    min-width: 38px;

    img {
      height: 38px;
      max-width: max-content;
    }
  }

  &.partner-img {
    height: 30px;
    width: 30px;

    img {
      height: 30px;
      max-width: max-content;
    }
  }
}

.back-icon-align {
  position: relative;
  top: 4px;
}

.index-parent {
  padding: 16px 0px;
  border: 1px solid #000000;
  border-radius: 16px;
  position: fixed;
  top: 84px;
  max-width: 275px;
  background: #fff;
  z-index: 2;
  width: 230px;

  li {
    padding: 8px 16px;
    cursor: pointer;
    color: #333333;
  }

  li.active-section {
    padding-right: 0px;
    color: #333333;
    cursor: pointer;
    font-weight: 700;
    background: rgba(247, 207, 102, 0.2);
    border-right: 4px solid #f7cf66;
  }

  .pl-16 {
    padding-left: 16px;
  }
}

.partner-info-iconparent {
  padding: 2px;
  height: 20px;
  width: 20px;
  background: rgba(1, 1, 1, 1);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
  }
}

.partner-card-link {
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
}

.right-line {
  border-right: 1px solid #000;
}

table {
  width: 100%;

  td img {
    width: 100%;
  }
}

.story-detail-section {
  padding-top: 32px;
}

.story-detail-section.content-resources {
  padding-top: 64px;
}

.story-image {
  height: 170px;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.project-image {
  height: 80px;
}

.project-image path {
  fill: red;
}

.projectCard {
  min-height: 280px;
}

.projectCardadmin {
  min-height: 300px;
}

.story-vertical-align-card {
  display: grid;
  grid-template-rows: 1fr max-content;
}

.min-h-fix-guides {
  min-height: 235px;
}

.min-h-fix-story {
  min-height: 300px;
}

.card-action-star {
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  background: #ffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1450px) {
  .min-h-fix-story {
    min-height: 325px;
  }
}

@media (max-width: 768px) {
  .min-h-fix-story {
    min-height: 300px;
  }

  .index-mobileview {
    position: fixed;
    right: 15px;
    bottom: 25px;
    box-shadow: 0px 1px 3px 0px #0000004d;
    box-shadow: 0px 4px 8px 3px #00000026;
    width: 44px;
    height: 44px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--Black-SaaS22, #010101);
    border-radius: 13px;
    background: #fff;
    z-index: 9;
  }

  .primary-tab.parent-tab {
    .ant-tabs-nav {
      display: none;
    }
  }

  .primary-tab.child-tab {
    .ant-tabs-nav {
      display: block !important;
    }
  }

  .primary-tab.child-tab.deals-tab {
    .ant-tabs-nav {
      display: flex !important;
    }
  }
}