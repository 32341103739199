.whitespace-promo {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

@media (max-width: 1200px) {
    .whitespace-promo {
        max-width: 120px;
    }

}