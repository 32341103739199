.event-round-full {
  height: 40px;
  width: 40px;
  border: 1px solid #d0d4da;
  border-radius: 50%;
  overflow: hidden;
  align-items: center;
  display: flex;
}

.event-page {
  .custom-select .ant-select-selection-placeholder {
    color: #374151;
  }

  ::-webkit-input-placeholder {
    color: #374151 !important;
  }
}

.events-page .ant-tabs-nav {
  margin-bottom: 5px;
}

.tag-rounded {
  border-radius: 4px !important;
}
