.partner-card {
  border: 1px solid #333333;
  border-radius: 8px;
  padding: 24px;
  min-height: auto !important;
}

.vendor-partner-img {
  border: 1px solid #ededed;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  overflow: hidden;
}

.partner-logo {
  width: 28px;
  height: 28px;
  border: 0.36px solid #d0d4da;
  padding: 4px;
}
.partner-border {
  border: 1px solid #d0d4da;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.non-clickable-card {
  cursor: no-drop;
  opacity: 30%;

  &:hover {
    box-shadow: none !important;
  }
}

.edit-properties {
  border: 1px solid var(--Border, #d0d4da);
}

.edit-properties2 {
  max-height: calc(100vh - 175px);
  overflow: hidden;
  overflow: auto;
}

.edit-properties-firstsection {
  height: calc(100vh - 130px);
  overflow: hidden;
  overflow: auto;
}

.row-section {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.0509803922);
  padding: 20px;
  margin: 5px;
  margin-top: 7px;
  margin-right: 8px;
}

.parent-cta-vendor {
  margin: 0px auto;
  height: calc(100vh - 200px);

  .cta-vendor {
    border: 1px solid #010101;
    padding: 32px;
    display: grid;
    gap: 20px;
    border-radius: 12px;
    margin: 0px 0px auto;
  }
}

.ant-table-wrapper.expandatable-row {
  .ant-table-cell {
    .ant-table-thead {
      display: none !important;
    }

    // .ant-table-wrapper .ant-table-tbody > tr > td{
    //     padding: 16px 0px 16px 0px;
    // }
    .ant-spin-nested-loading .ant-spin-container {
      margin: 0px -15px 0px -15px;
    }

    .ant-table-tbody {
      background: #f9f9f9;
    }
  }

  .ant-table {
    margin-inline: 0px !important;
  }
}

.ant-table-row.ant-table-row-level-1 {
  display: none;
}

.chatgpt-button:disabled {
  opacity: 0.4;
}

.stories-container a:hover,
.webfeed-container a:hover {
  font-weight: 600;
}

.ellipse-hover:hover + .hidden {
  display: block;
}
.hidden:hover {
  display: block;
}

.hide-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.text-indent {
  // text-indent: 2em;
  display: block;
  margin-bottom: 10px;
  line-height: 1.8;
}

.ai-answer h4 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  line-height: 1.8;
}

.ai-answer p,
.ai-answer span {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 5px;
}

.ai-answer li {
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 5px;
}

textarea.height-40 {
  height: 40px !important;
}

.custom-style-popup-confirm-input {
  border: 1px solid #ededed;
  width: 100%;
  border-radius: 5px;
  height: 170px;
  outline: none;
}

.ant-popconfirm-message-text {
  width: 300px;
  height: 200px;
}
.ant-popconfirm-buttons {
  display: flex;

  .ant-btn {
    height: 30px;
    font-size: 12px;
    font-weight: 700;
    padding: 2px 16px;
  }

  .ant-btn-primary {
    min-width: auto;
  }

  .ant-btn-default {
    min-width: auto;
  }
}
