// sidebar
@import "../../variables";

.sidebar-components {
  height: calc(100vh - 65px);
  position: sticky;
  top: 65px;
  transition: 0.5s;

  .custom-menu-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 64px;
  }

  .ant-menu {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 20px;
    gap: 15px;
    padding: 0px 5px;
    margin-top: 2px;
  }

  .ant-menu-root.ant-menu-inline,
  .ant-menu.ant-menu-root.ant-menu-inline,
  .ant-menu-root.ant-menu-vertical,
  .ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: 0px;
  }

  .ant-menu-light .ant-menu-item-selected,
  .ant-menu-light > .ant-menu .ant-menu-item-selected {
    background-color: $goldenrod;
    font-weight: 600;
    color: $dark-gray;
  }

  .ant-menu-item {
    line-height: 25px;
    margin: 0px;
  }
}

.hideSidebar {
  position: absolute;
  left: -150px;
  transition: 0.5s;

  &.active {
    left: -0px;
    transition: 0.5s;
  }
}

.sidebar-components {
  width: 7rem;

  &.active {
    width: 7rem;
  }
}

.sidebar-scroll-set {
  height: calc(100vh - 66px);
  overflow: hidden;
  overflow-y: auto;
  background: #fff;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.sidebar-scroll-set::-webkit-scrollbar {
  width: 1px;
}

.sidebar-scroll-set::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-scroll-set::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 2px;
}

.mobile-view-header.mobile-sidebar {
  .ant-menu-root.ant-menu-inline,
  .ant-menu.ant-menu-root.ant-menu-inline,
  .ant-menu-root.ant-menu-vertical,
  .ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: 0px;
  }

  .ant-menu-light .ant-menu-item-selected,
  .ant-menu-light > .ant-menu .ant-menu-item-selected {
    background-color: $goldenrod;
    font-weight: 600;
    color: $dark-gray;
  }

  .ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
  .ant-menu-light > .ant-menu .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #000;
    // padding-left: 19px !important;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    margin: 0;
    margin-top: 4px;
  }

  .sub-menu-mobile .ant-menu-item {
    padding-left: 57px !important;
  }

  .ant-menu-light .ant-menu-item:hover {
    background-color: $goldenrod;
  }

  .ant-menu-item {
    line-height: 25px;
    margin: 0px;
    margin-bottom: 2px;
  }

  .ant-drawer-body {
    padding: 10px;
  }

  .ant-menu-sub.ant-menu-inline {
    background: #fff;
  }
}

@media (max-width: 768px) {
  .sidebar-components {
    width: auto;
    z-index: 3;

    &.active {
      position: fixed;
      z-index: 3;
      background: #fff;
      box-shadow: 0px 1px 3px 0px #0000001a;
    }
  }
}

// skeleton
.sidebar-components {
  .ant-skeleton-title {
    display: none;
  }

  .ant-skeleton .ant-skeleton-content {
    padding: 0px 10px;
  }

  .ant-skeleton-paragraph {
    li {
      width: 5rem !important;
      height: 64px !important;
      border-radius: 10px;
    }
  }
}
