.projectRequestpage {
    .ant-segmented {
        border-radius: 8px;
    }

    .ant-segmented .ant-segmented-item-label {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 33px;
        line-height: 34px;
        padding: 0 8px;
        opacity: 0.5;
    }

    .ant-segmented-item.ant-segmented-item-selected .ant-segmented-item-label {
        opacity: 1;
    }

    .kanban {
        display: flex;
        flex: 1 1 auto;
        overflow: auto;
        scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
        height: calc(100vh - 254px);
    }

    .kanban-container {
        display: flex;
        flex-direction: row;
    }

    .kanban-column {
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        min-width: 310px;
        max-width: 340px;
        border-radius: 4px;
        background-color: #eee;
    }

    .kanban-column-header {
        padding: 10px;
        font-weight: bold;
        font-size: 0.875rem;
    }

    .kanban-column-list {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;
        margin: 0;
        list-style: none;
        background: #ffffff;
        min-height: calc(100vh - 315px);
    }

    .kanban-div {
        background: #eeeeee;
        padding: 0px 10px 0px 10px;
    }

    .kanban-column-footer {
        padding: 10px;
        font-size: 0.875rem;
    }

    .card {
        padding: 10px;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        background-color: #fff;
        margin-bottom: 10px;
        cursor: grab;
    }

    .card:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .card {
        border: 1px solid #dcdcdc;
        cursor: grab;
        touch-action: none;
    }

    .card.dragging {
        opacity: 0.5;
    }

    .card.drag-overlay {
        box-shadow: 0 0 0 calc(1px / 1) rgba(63, 63, 68, 0.05),
            -1px 0 15px 0 rgba(34, 33, 81, 0.01),
            0px 15px 15px 0 rgba(34, 33, 81, 0.25);
        border: 1px solid rgba(64, 150, 255, 1);
        cursor: grabbing;
    }

    .kanban-view-badge {
        .ant-scroll-number.ant-badge-count {
            background: #E0E7FF;
            border: 1px solid rgb(224, 231, 255);
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media (pointer: coarse) {
        .card {
            touch-action: manipulation;
        }
    }
}

.ant-select-item-option-disabled {
    opacity: 0.3;
}

.padding-radius {
    padding: 12px;
    border-radius: 8px;
}