.profile-img img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f2f2f2;
}

.drawer-bottom-none .ant-drawer-header {
    border-bottom: none;

}

.pb-none {
    .ant-drawer-body {
        padding-bottom: 0px;
    }
}