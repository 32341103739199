$themeBlue: #5d5ff3;
$themeRed: #ea5a54;
$themeyellow: #f7cf66;
$themeGreen: #55af68;
$common-route-background: #f0f0f0;
$dark-gray: #333333;
$royal-blue: rgba(93, 95, 243, 1);
$steel-gray: #6b7280;
$midnight-blue: #111827;
$light-gray: #e5e7eb;
$goldenrod: #f7cf66;
$silver: #d0d4da;
$white: #fff;
$black: #000;
$translucent-black: rgba(0, 0, 0, 0.1);
$medium-blue: #1e40af;
$mint: rgba(80, 171, 100, 0.2);
$forest-green: #3c7a46;
$transparent-black: #0000001a;
$charcoal-gray: #1c1c1c;
$slate-blue: #374151;
$sunshine-yellow: #ffcd4f;
$powder-blue: #dbeafe;
$black: #000;
$TransparentBlack: #0000000d;
$LightGrayish: #d1d5db6b;
$LightSilver: #d1d5db;
$Grayish: #7b7b7b;
$Tertiary: #a9a9a9;
