@import '../../_variables.scss';

.Saas-header {
    min-height: 64px;
    position: sticky;
    top: 0px;
    box-shadow:
        0 1px 3px 0 rgba(0, 0, 0, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.05);
    z-index: 20;
}
